import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Avatar, 
  Button, 
  CssBaseline, 
  TextField,
  FormHelperText,
  Grid,
  Box,
  Typography,
  Container
}from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Heading from '../../components/Heading';
import { toast } from 'react-toastify';
import { validateEmail, validatePassword } from '../../helpers/commonHelper';
import ApiHelper from '../../helpers/apiHelper';

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [valid, setValid] = useState({
    email: false,
    password: false,
    confirmPwd: false
  });

  const [errorText, setErrorText] = useState({
    email: '',
    password: '',
    confirmPwd: ''
  });

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      setErrorText({...errorText, [e.target.name]: ''});
      setValid({...valid, [e.target.name]: false});
    }
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
      confirmPwd: {value: string};
    };

    const email = target.email.value;
    const password = target.password.value;
    const confirmPwd = target.confirmPwd.value;

    if (email === '') {
      setErrorText({...errorText, email: 'Email is required'});
      setValid({...valid, email: true});
      return;
    }

    if (!validateEmail(email)) {
      setErrorText({...errorText, email: 'Email is invalid'});
      setValid({...valid, email: true});
      return;
    }

    if (password === '') {
      setErrorText({...errorText, password: 'Password is required'});
      setValid({...valid, password: true});
      return;
    }

    if(!validatePassword(password)) {
      setErrorText({...errorText, password: 'Password should be at least 8 characters, and should contain capital & lower letter and number.'});
      setValid({...valid, password: true});
      return;
    }

    if (confirmPwd === '') {
      setErrorText({...errorText, confirmPwd: 'Confirm password is required'});
      setValid({...valid, confirmPwd: true});
      return;
    }

    if (password !== confirmPwd) {
      setErrorText({...errorText, confirmPwd: 'Password does not match.'});
      setValid({...valid, confirmPwd: true});
      return;
    }

    ApiHelper.post('/api/auth/reset_password/', {
      email: email,
      password: password
    }, {}, false).then(() => {
      toast.success("Success!");
      navigate("/phone_verification");
    }).catch(() => {
      toast.error("Fail!")
    })
  };

  return (
    <React.Fragment>
      <Heading text=""/>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div className="login_card">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
              <Box 
                component="form" 
                noValidate 
                sx={{ mt: 1 }}
                onSubmit={handleSubmit} 
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      label="Email Address"
                      name="email"
                      autoFocus
                      error={valid.email}
                      onChange={handleChangeInput}
                    />
                    <FormHelperText className='text-danger'>{errorText.email}</FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      error={valid.password}
                      onChange={handleChangeInput}
                    />
                    <FormHelperText className='text-danger'>{errorText.password}</FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      name="confirmPwd"
                      label="Confirm Password"
                      type="password"
                      error={valid.confirmPwd}
                      onChange={handleChangeInput}
                    />
                    <FormHelperText className='text-danger'>{errorText.confirmPwd}</FormHelperText>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Container>
      </div>
      </Box>
    </React.Fragment>
  );
}