import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Button,
  Grid,
  Typography,
  OutlinedInput,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NumberFormat, { SourceInfo } from 'react-number-format';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ApiHelper from "../../helpers/apiHelper";
import CryptoLogo from "../../components/CryptoLogo";
import Heading from "../../components/Heading";

type BucketData = {
  bucket_name: string,
  bucket_description: string,
  bucket_allocation: number,
  bucket_volatility: string,
  bucket_asset_pairs: {
    ticker: string,
    weightage: number
  }[]
}

interface PercentValueObject {
  [key: string]: any
}

export default function Recommendation() {
  const [value, setValue] = useState<PercentValueObject>({});
  const [bucketData, setBucketData] = useState<Array<BucketData>>([]);
  const [percentValueSum, setPercentValueSum] = useState<number>();
  const navigate = useNavigate();

  useEffect(() => {
    ApiHelper.get("/api/get_bucket_recommendations")
    .then((res: any) => {
      const {bucket_recommendations} = res.data;
      setBucketData(bucket_recommendations);
      const percentValues: PercentValueObject = {};
      for(let i = 0; i < bucket_recommendations.length; i++) {
        percentValues[bucket_recommendations[i].bucket_name] = bucket_recommendations[i].bucket_allocation;        
      }
      setValue(percentValues);
    }).catch(() => {
      toast.error("Fail! Please try again.");
    });
  }, []);

  useEffect(() => {
    setPercentValueSum(Object.values(value).reduce((a, b) => parseInt(a) + parseInt(b), 0));
  }, [value]);

  const handleChange = (number: string, e: SourceInfo) => {
    if (e.event != null) {
      setValue({...value, [e.event.target.name]: number});
    }
  };

  const handlePrevious = () => {
    navigate("/survey/crypto_stable");
  }

  const handleContinue = () => {
    if (percentValueSum !== 100) {
      toast.warn("Bucket Allocations must equal to 100.");
      return true
    }

    ApiHelper.post("/api/save_user_bucket_allocations", {
      bucket_allocations: value
    }).then(() => {
      navigate("/fund_account/stake_blockchain_assets");
    }).catch(() => {
      toast.error("Fail! Please try again.");
    })
  }

  return (
    <>
      <Heading text="Portfolio Constructions" />
      <Grid className="main">
        <ArrowBackIosNewIcon onClick={handlePrevious}/>
        <Box 
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography className="page_title">Your personalized digital asset portfolio</Typography>
          <Typography className={percentValueSum != 100 ? "percent-value-error" : "percent-value"}>{percentValueSum}%</Typography>
          <Typography sx={{fontWeight: "bold"}}>Total allocation</Typography>
          <Box maxWidth="sm">
            <Typography sx={{ mt: 2, mb: 2}}>
            Customize your overall portfolio by allocating how much of each basket you want to own. Each bucket here is weighted by market cap and will be rebalanced over time.
            Access to stable APY Decentralized Finance protocols is coming soon!
            </Typography>
            {bucketData && bucketData.map((data, key) => (  
              <Box className="survey_list_item" sx={{marginBottom: "10px", padding: "10px"}} key={key}>
                <Grid container spacing={2}>
                <Grid item xs={5} sm={5}>
                  <Typography className="title">{data.bucket_name}</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography className="title">Volatility: {data.bucket_volatility}</Typography>
                </Grid>
                <Grid item xs={3} sm={3} sx={{display: "flex"}}>
                  <Typography className="allocation">Allocation</Typography>
                  <Grid sx={{marginLeft: "10px"}}>
                    <NumberFormat
                      name={data.bucket_name}
                      suffix={'%'}
                      style={{borderRadius: "15px", width: "80%"}}
                      customInput={OutlinedInput}
                      inputProps={{
                        style: { textAlign: "center", padding: "5px"},
                      }}
                      value={value[data.bucket_name]}
                      onValueChange={(values, e) => handleChange(values.value, e)}
                    />
                    <Typography sx={{fontSize: "10px"}}>Total across baskets must sum to 100%</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Typography style={{fontSize: "12px"}}>{data.bucket_description}</Typography>
              <Grid container spacing={1}>
              {data.bucket_asset_pairs.map((pair, key) => (
                <Grid item xs={3} sm={3} key={key}>
                  <List>
                    <ListItem className="btn-primary">
                      <ListItemAvatar style={{marginRight: "-30px", marginTop: "3px"}}>
                        <CryptoLogo name={pair.ticker} width="20px" height="20px" />
                      </ListItemAvatar>
                      <ListItemText>
                        <Typography sx={{fontSize: "12px"}}>{pair.ticker} {pair.weightage}%</Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
                ))}
              </Grid>
              </Box>
            ))}

            <Button
              fullWidth 
              variant="contained"
              sx={{mt: 3}}
              className="submit-button"
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  )
}