import React from 'react';
import { List, ListItem, ListItemText, Divider, Typography } from '@mui/material';
// import { Check } from '@mui/icons-material';

type SidebarProps = {
  portfolio?: boolean,
  fund_account?: boolean,
}

export default function Sidebar(props: SidebarProps) {

  return (
    <div className="sidebar">
      <List>
        <ListItem className="padding-40">
          <p className="sidebar_title">
            3 Step Process you can close within a minute
          </p>
        </ListItem>
        <Divider className="sidebar_break" />
        <ListItem className="sidebar_list">
          <ListItemText
            primary={
              <Typography className="sidebar_list_title">Individual Details</Typography>
            }
          />
          {/* <Check className="check_active"/> */}
        </ListItem>
        <Divider className="sidebar_break" />
        <ListItem className="sidebar_list">
          <ListItemText
            primary={
              <Typography className={props.portfolio == true ? "sidebar_list_title" : "sidebar_list_inactive"} >
                Portfolio Construction
              </Typography>
            }
          />
          {/* {props.portfolio_done == true ? <Check className="check_active"/> : null} */}
        </ListItem>
        <Divider className="sidebar_break" />
        <ListItem className="sidebar_list">
          <ListItemText
            primary={
              <Typography className={props.fund_account == true ? "sidebar_list_title" : "sidebar_list_inactive"}>
                Fund your account
              </Typography>
            }
          />
          {/* {props.fund_account_done == true ? <Check className="check_active"/> : null} */}
        </ListItem>
        <Divider className="sidebar_break" />
      </List>
    </div>
  )
}