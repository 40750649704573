import { AuthActionTypes, AuthState } from '../actions/types';

const initialState: AuthState = {
  token: localStorage.getItem("token"),
  isLoading: false,
  isAuthenticated: false,
  user: null,
};

export default function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case AuthActionTypes.LOGIN_REQUEST:
    case AuthActionTypes.SIGNUP_REQUEST:
    case AuthActionTypes.TWO_FACTOR_AUTHENTICATION_REQUEST:
    case AuthActionTypes.GET_USER_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case AuthActionTypes.LOGIN_SUCCESS:
    case AuthActionTypes.SIGNUP_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        user: action.payload.user,
        isLoading: false,
        token: action.payload.token
      };

    case AuthActionTypes.LOGIN_FAILURE:
    case AuthActionTypes.LOGOUT:
    case AuthActionTypes.SIGNUP_FAILURE:
    case AuthActionTypes.GET_USER_INFO_FAILURE:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };

    case AuthActionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload
      };
    case AuthActionTypes.TWO_FACTOR_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
      };
    case AuthActionTypes.TWO_FACTOR_AUTHENTICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
      };
    default:
      return state
  }
}