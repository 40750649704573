import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from './components/PrivateRoute';
import Home from "./containers/Home";
import Login from "./containers/Auth/Login";
import SignUp from "./containers/Auth/Signup";
import EmailVerification from "./containers/Auth/EmailVerification";
import PhoneVerification from "./containers/Auth/PhoneVerification";
import ForgotPassword from './containers/Auth/ForgotPassword';
import Dashboard from './containers/Dashboard';
import LinkBank from './containers/Auth/LinkBank';
import IdentityVerification from './containers/Auth/IdentityVerification';
import AddPhone from './containers/Auth/AddPhone';
import Survey from './containers/Auth/Survey';
import FundAccount from './containers/Auth/FundAccount';
import Staking from './containers/Staking';

console.log('PROCESS ENV');
console.log(process.env);
console.log('PROCESS ENV');

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/email_verification" element={<EmailVerification />} />
        <Route path="/signup/add_phone" element={<AddPhone />} />
        <Route path="/:status/phone_verification" element={<PhoneVerification />} />
        <Route path="/identity_verification" element={<IdentityVerification />} />
        <Route path="/link_bank" element={<LinkBank />} />
        <Route path="/survey/:sub_title" element={<Survey />} />
        <Route path="/fund_account/:sub_category" element={<FundAccount />} />
        <Route path="/fund_account/link_bank" element={<LinkBank />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/staking" element={<PrivateRoute><Staking /></PrivateRoute>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
