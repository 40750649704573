import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { 
  Button, 
  CssBaseline, 
  FormHelperText,
  Grid,
  Box,
  Link,
  Typography,
  Container,
  InputLabel,
  OutlinedInput
}from '@mui/material';
import { toast } from 'react-toastify';
import { validateEmail } from '../../helpers/commonHelper';
import { login } from "../../redux/actions/auth";
import logoImg from '../../assets/images/logo_full.png';

const Login = (props: any) => {
  const navigate = useNavigate();
  const [valid, setValid] = useState({
    email: false,
    password: false
  });
  const [isLogged, setIsLogged] = useState(false);

  const [errorText, setErrorText] = useState({
    email: '',
    password: ''
  });

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      setErrorText({...errorText, [e.target.name]: ''});
      setValid({...valid, [e.target.name]: false});
    }
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
    };

    const email = target.email.value;
    const password = target.password.value;

    if (email === '') {
      setErrorText({...errorText, email: 'Email is required'});
      setValid({...valid, email: true});
      return;
    }

    if (!validateEmail(email)) {
      setErrorText({...errorText, email: 'Email is invalid'});
      setValid({...valid, email: true});
      return;
    }

    if (password === '') {
      setErrorText({...errorText, password: 'Password is required'});
      setValid({...valid, password: true});
      return;
    }

    props.login(email, password)
    .then(() => {
      setIsLogged(true);
    })
    .catch((err: any) => {
      if (err.response.status === 404) {
        toast.error("There is no such user, please signup first.");
        navigate("/signup");
      } else {
        toast.warn("Your email or password is incorrect, please try again.");
      }
    })
  };

  useEffect(() => {
    if (isLogged) {
      if (props.user && props.user.email_verified == false) {
        navigate('/email_verification');
      } else if (props.user && props.user.is_identified == false) {
        navigate('/identity_verification');
      } else if (props.user && props.user.done_survey == false) {
        navigate('/survey/funds_plan');
      } else if (props.user && props.user.done_survey == true) {
        navigate('/login/phone_verification');
      }
    }
  }, [isLogged]);

  return (
    <React.Fragment>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 30,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logoImg} />
          <Box
            component="form" 
            noValidate 
            sx={{ mt: 1 }}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2} sx={{mt: 5}}>
              <Grid item xs={12}>
                <InputLabel className="input-label">Email</InputLabel>
                <OutlinedInput
                  fullWidth
                  name="email"
                  error={valid.email}
                  onChange={handleChangeInput}
                />
                <FormHelperText className='text-danger'>{errorText.email}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="input-label">Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  name="password"
                  type="password"
                  error={valid.password}
                  onChange={handleChangeInput}
                />
                <FormHelperText className='text-danger'>{errorText.password}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 6, mb: 3, width: "100%" }}
                  className="submit-button"
                >
                  Log In
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography className="default-font">
                  Didn&apos;t have an account? <Link href="/signup" className="default-font">Sign Up</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );  
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch: any) => ({
  login: (email: string, password: string) => dispatch(login(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);