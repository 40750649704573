import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Grid,
  Link,
  Button,
  Menu,
  MenuItem
} from "@mui/material";
import logoWhiteImg from "../../assets/images/logo_white.png";
import { logout } from "../../redux/actions/auth";

type NavBarProps = {
  active: string,
  user: {first_name: string, last_name: string},
  logout: () => void
}

function NavBar(props: NavBarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!props.user) {
    return null
  } else {
    return (
      <Box className="navbar">
        <Grid container>
          <Grid item xs={1} sm={1}>
            <img src={logoWhiteImg} className="logo-img"/>
          </Grid>
          <Grid item xs={8} sm={8} className="nav-items">
            <Link href="/dashboard" className={props.active === "dashboard" ? "nav-item active" : "nav-item"}>
              <span>Dashboard</span>
            </Link>
            <Link href="/staking" className={props.active === "staking" ? "nav-item active" : "nav-item"}>
              <span style={{marginLeft: "30px"}}>Staking</span>
            </Link>
          </Grid>
          <Grid item xs={3} sm={3} className="nav-items">
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className="nav-menu"
            >
              {props.user.first_name} {props.user.last_name}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => props.logout()}>Logout</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Box>
    );
  }
  
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);