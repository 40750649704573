import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Box,
  FormHelperText,
  FormGroup
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import Heading from '../../components/Heading';
import ApiHelper from '../../helpers/apiHelper';
import Sidebar from '../../components/Sidebar';
import addPhoneImg from "../../assets/images/add_phone.png";

export default function AddPhone() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');

  useEffect(() => {
    if (phoneNumber !== '') {
      setErrorText('');
    }
  }, [phoneNumber]);

  const handleSend = () => {
    if (phoneNumber === '') {
      setErrorText('Phone number is required.');
      return true
    }

    ApiHelper.post('/api/auth/send_sms/', {
      phone_number: `+${phoneNumber}`
    }).then(() => {
      toast.success("Success! Please check your phone.");
      navigate("/signup/phone_verification");
    }).catch(() => {
      toast.error("Fail! Please try again.");
    })
  }

  return (
    <Grid className="container">
      <Sidebar />
      <Heading text="Setup 2FA" />
      <Grid className="main">
        <Box
          sx={{
            mt: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={addPhoneImg} />
          <Grid container spacing={2} justifyContent="center" sx={{mt: 6, mb: 5}}>
            <FormGroup>
              <PhoneInput
                country={'us'}
                value={phoneNumber}
                onChange={(value: string) => setPhoneNumber(value)}
              />
              <FormHelperText className='text-danger'>{errorText}</FormHelperText>
              <Button
                fullWidth
                sx={{mt: 3}}
                variant="contained"
                onClick={handleSend}
                className="submit-button"
              >
                Submit
              </Button>
            </FormGroup>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
