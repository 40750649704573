export enum AuthActionTypes {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGOUT = "LOGOUT",
  GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST",
  GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE",
  SIGNUP_REQUEST = "SIGNUP_REQUEST",
  SIGNUP_SUCCESS = "SIGNUP_SUCCESS",
  SIGNUP_FAILURE = "SIGNUP_FAILURE",
  TWO_FACTOR_AUTHENTICATION_REQUEST = "TWO_FACTOR_AUTHENTICATION_REQUEST",
  TWO_FACTOR_AUTHENTICATION_SUCCESS = "TWO_FACTOR_AUTHENTICATION_SUCCESS",
  TWO_FACTOR_AUTHENTICATION_FAILURE = "TWO_FACTOR_AUTHENTICATION_FAILURE",
}

export interface AuthState {
  token: string | null,
  isLoading: boolean,
  isAuthenticated: boolean,
  user: null,
}