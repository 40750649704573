import React, { useState, useEffect } from "react";
import {
  Box, 
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { toast } from "react-toastify";
import NavBar from '../../components/Navbar';
import ApiHelper from "../../helpers/apiHelper";
import BalanceNav from "../../components/Dashboard";
import CryptoLogo from "../../components/CryptoLogo";
import EmptyDashboard from "../../components/Dashboard/EmptyDashboard";

type StakedData = {
  name: string,
  num_staked: string,
  asset_ticker: string,
  rewards: string,
  expected_apy: string
}[]

export default function Staking() {
  const [stakedData, setStakedData] = useState<StakedData>();
  const [stakedGrpahData, setStakedGraphData] = useState();
  const [balanceData, setBalanceData] = useState({
    account_value: 0,
    account_rewards: 0,
    net_contributions: 0
  });

  useEffect(() => {
    ApiHelper.get("/api/get_user_account_financials")
    .then((res: any) => {
      setBalanceData(res.data);
    }).catch(() => {
      toast.error("Please try again.");
    });
  }, []);

  useEffect(() => {
    ApiHelper.get("/api/get_staked_data")
    .then((res: any) => {
      setStakedData(res.data);
    }).catch(() => {
      toast.error("Please try again.");
    })
  }, []);

  useEffect(() => {
    ApiHelper.get("/api/get_staked_graph_data")
    .then((res: any) => {
      setStakedGraphData(res.data);
    }).catch(() => {
      toast.error("Please try again.");
    })
  }, []);

  if (!balanceData) {
    return null
  } else {
    if (balanceData.net_contributions == 0) {
      return (
        <Grid className="container">
          <NavBar active="staking" />
          <EmptyDashboard />
        </Grid>
      )
    } else {
      return (
        <Grid className="container">
          <NavBar active="staking" />
          <BalanceNav data={balanceData} />
          <Divider style={{width: "100%"}} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: "20px"
            }}
          >
            <Typography className="chart-title">Staking</Typography>
            <ResponsiveContainer width="90%" height={500}>
              <LineChart
                data={stakedGrpahData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis label={{ value: 'Dollars($)', angle: -90, position: 'insideLeft', dx: -20 }} />
                <Tooltip />
                <Line dataKey="total_usd_val" stroke="#265ADF"/>
              </LineChart>
            </ResponsiveContainer>
            <Grid container spacing={3} sx={{mt: 3}}>
              {stakedData && stakedData.map((data, key) => (
                <Grid item xs={3} sm={3} key={key}>
                <Box className="staking-card">
                  <Typography className="title" sx={{mb: 3}}>{data.name}</Typography>
                  <CryptoLogo name={data.asset_ticker} width="50px" height="50px" />
                  <Typography className="status-info" sx={{mt: 3}}>Currently Staking</Typography>
                  <Divider />
                  <Grid container spacing={2} sx={{padding: "5px 0 5px 0"}}>
                    <Grid item xs={6} sm={6}>
                      <Typography className="title">{data.name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Typography className="title">{data.num_staked} {data.asset_ticker}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={2} sx={{padding: "5px 0 5px 0"}}>
                    <Grid item xs={6} sm={6}>
                      <Typography className="title">Rewards</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Typography className="title">{data.rewards} {data.asset_ticker}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Typography className="footer-info">Expected APY: {data.expected_apy}%</Typography>
                </Box>
              </Grid>
              ))}
              
            </Grid>
          </Box>
        </Grid>
      )
    }
  }
}