import React, { useState, useEffect } from 'react';
import {
  Link,
  Grid,
  Box,
  Button,
  Typography,
  OutlinedInput,
  FormHelperText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import Heading from '../../components/Heading';
import ApiHelper from '../../helpers/apiHelper';
import Sidebar from '../../components/Sidebar';
import emailVerificationImg from "../../assets/images/email_verification.png";
import { getUserInfo } from "../../redux/actions/auth";

const EmailVerification = (props: any) => {
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');

  useEffect(() => {
    if (verificationCode !== '') {
      setErrorText('');
    }
  }, [verificationCode]);

  const handleResend = () => {
    ApiHelper.get('/api/auth/send_verification_email/')
    .then(() => {
      toast.success("Success! Please check your email to verify.");
    }).catch(() => {
      toast.error("Fail! Please try again.");
    })
  }

  const handleVerify = () => {
    if (verificationCode === '') {
      setErrorText('Email verification code is required.');
      return true
    }

    ApiHelper.post('/api/auth/email_verification/', {
      code: verificationCode
    }).then(() => {
      navigate("/signup/add_phone");
      props.getUserInfo();
    }).catch(() => {
      toast.error("Fail! Your verification code is incorrect.");
    })
  }

  return (
    <Grid className="container">
      <Sidebar />
      <Heading text="Email Verification" />
      <Grid className="main">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={emailVerificationImg} />
          <Grid container spacing={2} justifyContent="center" sx={{mt: 3, mb: 3}}>
            <Grid item sx={{textAlign: "center"}}>
            <Typography 
                className="default-font" 
                sx={{mb: 3, fontSize: '20px', lineHeight: "38px"}}
              >
                Please verify the email in your inbox
              </Typography>
              <OutlinedInput
                fullWidth
                placeholder="6 Digit Code"
                value={verificationCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVerificationCode(e.target.value)}
                inputProps={{
                  style: { textAlign: "center" },
                }}
              />
              <FormHelperText className="text-danger">{errorText}</FormHelperText>
              <Button
                fullWidth
                sx={{mt: 3, mb: 3}}
                variant="contained"
                onClick={handleVerify}
                className="submit-button"
              >
                Submit
              </Button>
              <Typography className="default-font">
                Didn&apos;t receive a mail? <Link href="#" onClick={handleResend}>Click here to resend</Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserInfo: () => dispatch(getUserInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);