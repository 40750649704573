import { store } from '../redux/store';
import ApiHelper from './apiHelper';
import { User } from '../models';

class AuthHelper {
  login = (username: string, password: string) => {
    return ApiHelper.post('/api/auth/login/', {username, password}, {}, false);
  };

  signup = (userData: User) => {
    return ApiHelper.post('api/auth/register/', {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      password: userData.password,
      date_of_birth: userData.dateOfBirth,
      ip_address: userData.ipAddress
    }, {}, false);
  }

  getUserInfo = () => {
    return ApiHelper.get('/api/auth/user/');
  };

  getAccessToken = () => {
    const state = store.getState();
    
    return state.auth.token;
  };

  phoneVerification = (code: string) => {
    return ApiHelper.post('/api/auth/phone_verification/', {
      code: code
    })
  }
}

export default new AuthHelper();

