import axios from 'axios';
import AuthHelper from './authHelper';
import { store } from '../redux/store';
import { AuthActionTypes } from "../redux/actions/types";

const withAuth = (headers = {}) => {
  return {
    ...headers,
    'Authorization': `Token ${AuthHelper.getAccessToken()}`
  }
};

const base = (method: any, url: string, data = {}, headers = {}, secure = true) => {
  if(secure) {
    return axios({
      method,
      url,
      baseURL: process.env.REACT_APP_BASE_URL,
      data,
      headers: withAuth(headers),
    }).catch(err => {
      if (err.response.status === 401) {
        store.dispatch({
          type: AuthActionTypes.LOGOUT
        });
      }
      throw err;
    });
  } else {
    return axios({
      method,
      url,
      baseURL: process.env.REACT_APP_BASE_URL,
      data,
      headers,
    })
  }
};

const ApiHelper: { [key: string]: any } = {};

const methods = ['get', 'post', 'put', 'patch', 'delete'];

methods.forEach((method: string) => {
  ApiHelper[method] = base.bind(null, method);
});

export default ApiHelper;
