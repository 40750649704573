const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePassword = (password: string) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  return re.test(password)
}

const numberWithCommas = (value: string) => {
  const values = value.split('.');
  if (values[1] === '00') {
    return values[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export {
  validateEmail,
  validatePassword,
  numberWithCommas
}