import React from "react";
import {
  Grid,
  Typography,
  Divider
} from "@mui/material";
import { numberWithCommas } from "../../helpers/commonHelper";

type BalanceNavProps = {
  data: {
    account_value: number,
    account_rewards: number,
    net_contributions: number
  }
}

export default function BalanceNav(props: BalanceNavProps) {
  const { data } = props;

  return (
    <Grid container>
      <Grid item className="balance-items">
        <Typography className="balance-amount">
          ${numberWithCommas(data.account_value.toString())}
        </Typography>
        <Typography className="balance-name">Account Balance</Typography>
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Grid item className="balance-items">
        <Typography className="balance-amount" style={{color: "#27AE60"}}>
          ${numberWithCommas(data.account_rewards.toString())}
        </Typography>
        <Typography className="balance-name">Total Rewards</Typography>
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Grid item className="balance-items" style={{marginLeft: "auto"}}>
        <Typography className="balance-amount">
          ${numberWithCommas(data.net_contributions.toString())}
        </Typography>
        <Typography className="balance-name">Principal Investment</Typography>
      </Grid>
    </Grid>
  )
}