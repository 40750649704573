import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from 'react-router-dom';
import Heading from "../../components/Heading";
import Sidebar from "../../components/Sidebar";
import ApiHelper from "../../helpers/apiHelper";
import { toast } from "react-toastify";
import BlockLists from "../../components/Survey/BlockLists";
import CheckboxLists from "../../components/Survey/CheckboxLists";
import FundsPlan from "../../components/Survey/FundsPlan";


const Survey = () => {
  const {sub_title} = useParams();
  const [questionData, setQuestionData] = useState({
    title: '',
    sub_title: '',
    answers: []
  });
  const [previousQuestion, setPreviousQuestion] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    ApiHelper.get(`/api/survey/question/${sub_title}/`)
    .then((response: any) => {
      setQuestionData(response.data.question_data);
      setPreviousQuestion(response.data.previous_question);
    }).catch((err: any) => {
      if (err.response.status === 401) {
        toast.warn("Please login or signup first.");
        navigate("/login");
      } else {
        toast.error("Please try again.")
      }
    })
  }, [sub_title]);

  return (
    <Grid className="container">
      <Sidebar 
        // portfolio={true} 
        // portfolio_done={sub_title === "cryptocurrency_storage" ? true : false}
      />
      <Heading text="Individual Details" />
      <Grid className="main">
        {sub_title === "funds_plan" ? <FundsPlan sub_title={sub_title} data={questionData} /> : null}
        {(sub_title === "categorize_digital_asset" || sub_title === "crypto_stable") ? 
          <BlockLists sub_title={sub_title} data={questionData} previousQuestion={previousQuestion}/> : null
        }
        {sub_title === "digital_asset_goal" ? 
          <BlockLists sub_title={sub_title} data={questionData} checkbox={true} previousQuestion={previousQuestion} /> : null
        }
        {(sub_title === "cryptocurrency_type" || sub_title === "cryptocurrency_storage") ? 
          <CheckboxLists sub_title={sub_title} data={questionData} previousQuestion={previousQuestion} /> : null
        }
        {sub_title === "crypto_amount" ? 
          <CheckboxLists sub_title={sub_title} data={questionData} previousQuestion={previousQuestion} single={true} /> : null
        }
      </Grid>
    </Grid>
  )
}

export default Survey;