import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ApiHelper from "../../helpers/apiHelper";
import CryptoLogo from "../../components/CryptoLogo";
import Heading from "../../components/Heading";

type StakingData = {
  bucket_name: string,
  staking_description: string,
  staked_assets: {
    ticker: string,
    expected_apy: number
  }[]
}

const StakeBlockchainAssets = () => {
  const [stakingData, setStakingData] = useState<StakingData>();
  const navigate = useNavigate();

  useEffect(() => {
    ApiHelper.get("/api/get_staked_assets")
    .then((res: any) => {
      setStakingData(res.data.staking_apys);
    }).catch((err: any) => {
      console.log(err);
    })
  }, []);

  const handlePrevious = () => {
    navigate("/fund_account/recommendation");
  }

  return (
    <>
      <Heading text="Portfolio Constructions" />
      <Grid className="main">
        <ArrowBackIosNewIcon onClick={handlePrevious}/>
        <Box 
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box maxWidth="sm">
            <Typography className="page_title">You can stake these blockchain assets to earn rewards! Make your crypto earn crypto!</Typography>
            <Grid sx={{mt: 3}} className="staking_list">
              <Typography className="title">{stakingData?.bucket_name}</Typography>
              <Typography className="description">{stakingData?.staking_description}</Typography>
              <Grid container sx={{mt: 2}}>
                <Grid item xs={6} sm={6}>
                  <Typography className="list-title">Asset</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography className="list-title float-right">Staking APY</Typography>
                </Grid>
              </Grid>
              <Divider />
              <List>
                {stakingData?.staked_assets.map((data, key) => (
                  <>
                    <ListItem key={key}>
                      <ListItemAvatar>
                        <CryptoLogo name={data.ticker} width="20px" height="20px" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography sx={{fontSize: "12px"}}>{data.ticker}</Typography>
                        }
                      />
                      <Typography>{data.expected_apy} %</Typography>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </Grid>
            <Button
              fullWidth 
              variant="contained"
              sx={{mt: 3}}
              className="submit-button"
              onClick={() => navigate("/fund_account/link_bank")}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default StakeBlockchainAssets;