import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Button, 
  Box, 
  CssBaseline
} from '@mui/material';
import logoImg from '../../assets/images/logo_full.png';

export default function Home() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 30,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logoImg} />
          <Button
            size="large"
            variant="contained"
            sx={{ mt: 10, mb: 3, width: "50%" }}
            className="submit-button"
            onClick={() => navigate('/login')}
          >
            Log In
          </Button>
          <Button
            size="large"
            variant="contained"
            sx={{ mt: 3, mb: 2, width: "50%" }}
            className="submit-button"
            onClick={() => navigate('/signup')}
          >
            Sign Up
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  )
}