import React, { useState, useEffect } from "react";
import {
  Box,
  Link,
  Button,
  Grid,
  InputBase,
  Typography,
  Checkbox,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import NumberFormat from 'react-number-format';
import ApiHelper from "../../helpers/apiHelper";
import Heading from "../../components/Heading";
import { getUserInfo } from "../../redux/actions/auth";

const DepositAmount = (props: any) => {
  const [amount, setAmount] = useState<string>('');
  const [checked, setChecked] = useState({
    first: false,
    second: false,
    third: false
  });
  const navigate = useNavigate();
  const { user } = props;

  useEffect(() => {
    props.getUserInfo();
  }, []);

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({...checked, [e.target.name]: e.target.checked});
  }

  const handlePrevious = () => {
    navigate("/fund_account/link_bank");
  }

  const handleFinish = () => {
    if (amount == "") {
      toast.warn("Please type the amount");
      return true
    }

    if (user.linked_bank == true) {
      if (checked.first !== true || checked.second !== true || checked.third !== true) {
        toast.warn("Please agree with our policy.");
        return true
      }

      if (parseInt(amount) < 100 || parseInt(amount) > 5000) {
        toast.warn("Purchase amount must be in between 100 and 5,000.");
        return true
      }
    } else {
      if (parseInt(amount) < 100 || parseInt(amount) > 30000) {
        toast.warn("Purchase amount must be in between 100 and 30,000.");
        return true
      }
  
      if (checked.third !== true) {
        toast.warn("Please agree with our policy.");
        return true
      }
    }

    ApiHelper.post("/api/create_bucket_purchase", {
      usd_amount: parseInt(amount)
    }).then(() => {
      toast.success("Success");
      navigate("/dashboard");
    }).catch(() => {
      toast.error("Fail! Please try again.");
    })
  }

  return (
    <>
      <Heading text="Fund your account" />
      <Grid className="main">
        <ArrowBackIosNewIcon onClick={handlePrevious}/>
        <Box 
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box maxWidth="sm">
            <Typography className="page_title">How much would you like to deposit into earnJARVIS</Typography>
            <NumberFormat
              thousandSeparator={true}
              prefix={'$'}
              placeholder="$"
              className="deposit-placeholder"
              customInput={InputBase}
              inputProps={{
                style: { textAlign: "center" },
              }}
              value={amount}
              onValueChange={values => setAmount(values.value)}
            />
            <Typography className="deposit-description" sx={{mb: 2}}>Enter total fund amount</Typography>
            {user.linked_bank == true ?
              <>
                <Grid container spacing={2}>
                  <Grid item sm={1}>
                    <Checkbox
                      name="first"
                      checked={checked.first}
                      onChange={handleChecked}
                    />
                  </Grid>
                  <Grid item sm={11}>
                    <Typography>
                      I, {props.user.first_name} {props.user.last_name},  authorize earnJARVIS to electronically debit my Depository account for a one-time electronic funds transfer (EFT) payment for the amount and on the date identified. 
                      I also authorize earnJARVIS to debit or credit my account to correct any errors or process any refunds, if necessary.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{mt: 3}}>
                  <Grid item sm={1}>
                    <Checkbox
                      name="second"
                      checked={checked.second}
                      onChange={handleChecked}
                    />
                  </Grid>
                  <Grid item sm={11}>
                    <Typography>
                      I agree to the <Link href="https://www.circle.com/en/legal/us-user-agreement" target="_blank">Terms & Conditions</Link> and <Link href="https://www.circle.com/en/legal/us-privacy" target="_blank">Privacy Policy</Link> outlined by Circle, an earnJARVIS partner.
                    </Typography>
                  </Grid> 
                </Grid>
              </> : null
            }
            <Grid container sx={{mt: 3}}>
              <Grid item sm={1}>
                <Checkbox
                  name="third"
                  checked={checked.third}
                  onChange={handleChecked}
                />
              </Grid>
              <Grid item sm={11}>
                <Typography>
                  I understand that withdrawals will not be available for 12 weeks on earnJARVIS. If I need to withdraw in that time I will reach out to contact@earnjarvis.com.
                </Typography>
              </Grid>
            </Grid>
            <Button
              fullWidth 
              variant="contained"
              sx={{mt: 3}}
              className="submit-button"
              onClick={handleFinish}
            >
              Finish
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserInfo: () => dispatch(getUserInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(DepositAmount);