import React from 'react';
import logoImg from '../../assets/images/logo.png';
import { Grid, Typography } from "@mui/material";

type HeaderProps = {
  text: string
}

export default function Heading(props: HeaderProps) {
  return (
    <header>
      <Grid container>
        <Grid item xs={1} sm={1}>
          <a href="/">
            <img src={logoImg} alt="logo" className="logo-img" />
          </a>
        </Grid>
        <Grid item xs={9} sm={9} justifyContent="center">
          <p className="header_title">{props.text}</p>
        </Grid>
      </Grid>
    </header>
  )
}