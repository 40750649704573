import React from "react";
import btcImg from "../../assets/images/cryptologos/bitcoin-btc-logo.png";
import ethImg from "../../assets/images/cryptologos/ethereum-eth-logo.png";
import solImg from "../../assets/images/cryptologos/solana-sol-logo.png";
import adaImg from "../../assets/images/cryptologos/cardano-ada-logo.png";
import dotImg from "../../assets/images/cryptologos/polkadot-new-dot-logo.png";
import maticImg from "../../assets/images/cryptologos/polygon-matic-logo.png";
import lunaImg from "../../assets/images/cryptologos/terra-luna-luna-logo.png";
import algoImg from "../../assets/images/cryptologos/algorand-algo-logo.png";
import xtzImg from "../../assets/images/cryptologos/tezos-xtz-logo.png";
import avaxImg from "../../assets/images/cryptologos/avalanche-avax-logo.png";
import atomImg from "../../assets/images/cryptologos/cosmos-atom-logo.png";
import aaveImg from "../../assets/images/cryptologos/aave-aave-logo.png";
import uniImg from "../../assets/images/cryptologos/uniswap-uni-logo.png";
import compImg from "../../assets/images/cryptologos/compound-comp-logo.png";
import mkrImg from "../../assets/images/cryptologos/maker-mkr-logo.png";
import crvImg from "../../assets/images/cryptologos/curve-dao-token-crv-logo.png";
import yfiImg from "../../assets/images/cryptologos/yearn-finance-yfi-logo.png";
import axsImg from "../../assets/images/cryptologos/axie-infinity-axs-logo.png";
import manaImg from "../../assets/images/cryptologos/decentraland-mana-logo.png";
import enjImg from "../../assets/images/cryptologos/enjin-coin-enj-logo.png";
import filImg from "../../assets/images/cryptologos/filecoin-fil-logo.png";
import linkImg from "../../assets/images/cryptologos/chainlink-link-logo.png";
import grtImg from "../../assets/images/cryptologos/the-graph-grt-logo.png";
import dogeImg from "../../assets/images/cryptologos/dogecoin-doge-logo.png";
import shibImg from "../../assets/images/cryptologos/shiba-inu-shib-logo.png";

type CryptoLogoProps = {
  name: string,
  width: string,
  height: string
}

export default function CryptoLogo(props: CryptoLogoProps) {
  return (
    <>
      {props.name == "BTC" && <img src={btcImg} style={{width: props.width, height: props.height}} />}
      {props.name == "LINK" && <img src={linkImg} style={{width: props.width, height: props.height}} />}
      {props.name == "SOL" && <img src={solImg} style={{width: props.width, height: props.height}} />}
      {props.name == "AAVE" && <img src={aaveImg} style={{width: props.width, height: props.height}} />}
      {props.name == "ETH" && <img src={ethImg} style={{width: props.width, height: props.height}} />}
      {props.name == "UNI" && <img src={uniImg} style={{width: props.width, height: props.height}} />}
      {props.name == "COMP" && <img src={compImg} style={{width: props.width, height: props.height}} />}
      {props.name == "AVAX" && <img src={avaxImg} style={{width: props.width, height: props.height}} />}
      {props.name == "MANA" && <img src={manaImg} style={{width: props.width, height: props.height}} />}
      {props.name == "AXS" && <img src={axsImg} style={{width: props.width, height: props.height}} />}
      {props.name == "MKR" && <img src={mkrImg} style={{width: props.width, height: props.height}} />}
      {props.name == "FIL" && <img src={filImg} style={{width: props.width, height: props.height}} />}
      {props.name == "ADA" && <img src={adaImg} style={{width: props.width, height: props.height}} />}
      {props.name == "DOT" && <img src={dotImg} style={{width: props.width, height: props.height}} />}
      {props.name == "MATIC" && <img src={maticImg} style={{width: props.width, height: props.height}} />}
      {props.name == "LUNA" && <img src={lunaImg} style={{width: props.width, height: props.height}} />}
      {props.name == "ALGO" && <img src={algoImg} style={{width: props.width, height: props.height}} />}
      {props.name == "XTZ" && <img src={xtzImg} style={{width: props.width, height: props.height}} />}
      {props.name == "ATOM" && <img src={atomImg} style={{width: props.width, height: props.height}} />}
      {props.name == "CRV" && <img src={crvImg} style={{width: props.width, height: props.height}} />}
      {props.name == "YFI" && <img src={yfiImg} style={{width: props.width, height: props.height}} />}
      {props.name == "ENJ" && <img src={enjImg} style={{width: props.width, height: props.height}} />}
      {props.name == "GRT" && <img src={grtImg} style={{width: props.width, height: props.height}} />}
      {props.name == "DOGE" && <img src={dogeImg} style={{width: props.width, height: props.height}} />}
      {props.name == "SHIB" && <img src={shibImg} style={{width: props.width, height: props.height}} />}
    </>
  )
}