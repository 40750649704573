import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import {
  Button,
  Link,
  Grid,
  Box,
  Typography,
  FormGroup,
  OutlinedInput,
  FormHelperText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Heading from '../../components/Heading';
import ApiHelper from '../../helpers/apiHelper';
import Sidebar from '../../components/Sidebar';
import phoneVerificationImg from "../../assets/images/phone_verification.png";
import { phoneVerification } from "../../redux/actions/auth";

const PhoneVerification = (props: any) => {
  const {status} = useParams();
  const navigate = useNavigate();
  const [smsCode, setSmsCode] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');

  useEffect(() => {
    if (smsCode !== '') {
      setErrorText('');
    }
  }, [smsCode]);

  const handleSend = () => {
    ApiHelper.post('/api/auth/send_sms/', {
      phone_number: props.user.phone_number
    })
    .then(() => {
      toast.success("Success! Please check your phone.");
    }).catch(() => {
      toast.error("Fail! Please try again.");
    })
  }

  const handleVerify = () => {

    if (smsCode === '') {
      setErrorText('SMS code is required.');
      return true
    }

    props.phoneVerification(smsCode)
    .then(() => {
      if (status == "login") {
        navigate("/dashboard");
      } else {
        navigate("/identity_verification");
      }
    }).catch(() => {
      toast.error("Fail! Please try again.");
    })
  };

  // if (status === "login") {
  if ((status === "login") && props.user && props.user.email_verified == false) {
    return (
      <Navigate to="/email_verification" />
    )
  } else if ((status === "login") && props.user && props.user.is_identified == false) {
    return (
      <Navigate to="/identity_verification" />
    )
  } else if ((status === "login") && props.user && props.user.done_survey == false) {
    return (
      <Navigate to="/survey/funds_plan" />
    )
  } else {
    return (
      <React.Fragment>
        {(status === "signup") ? 
          (
            <>
              <Sidebar />
              <Heading text="Setup 2FA" />
            </>
          ) : null
        }
        <Grid className={status === "signup" ? 'main' : ''}>
          <Box
            sx={{
              mt: 12,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={phoneVerificationImg} />
            <Grid container spacing={2} justifyContent="center" sx={{mt: 6, mb: 5}}>
              <FormGroup sx={{textAlign: "center"}}>
                <Typography 
                  className="default-font" 
                  sx={{mb: 3, fontSize: '20px', lineHeight: "38px"}}
                >
                  Enter your verification code
                </Typography>
                <OutlinedInput 
                  placeholder="6 Digit Code"
                  value={smsCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSmsCode(e.target.value)}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                />
                <FormHelperText className="text-danger">{errorText}</FormHelperText>
                <Button
                  fullWidth
                  sx={{mt: 3, mb: 3}}
                  variant="contained"
                  onClick={handleVerify}
                  className="submit-button"
                >
                  Submit
                </Button>
                <Typography className="default-font">
                  Didn&apos;t receive a code? <Link href="#" onClick={handleSend} >Click here to resend</Link>
                </Typography>
              </FormGroup>
            </Grid>
          </Box>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = (dispatch: any) => ({
  phoneVerification: (code: string) => dispatch(phoneVerification(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerification);
