import React from "react";
import {
  Box, 
  Grid,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import backgroundImg from "../../assets/images/dashboard_no_data.png";
import { numberWithCommas } from "../../helpers/commonHelper";

const EmptyDashboard = (props: any) => {
  const { user } = props;

  if (!user.fund_amount) {
    return null
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 7
        }}
      >
        {user.linked_bank == true ?
          <Box maxWidth="sm" sx={{mb: 8}}>
            <Typography className="chart-title"> 
              Hi {user.first_name} {user.last_name}, your bucket purchase request has been received and your crypto portfolio is on its way.
            </Typography>
          </Box> :
          <>
            <Typography className="chart-title" sx={{mb: 5}}>
              Hi {user.first_name} {user.last_name}, Welcome to earnJARVIS.
            </Typography>
            <Typography>Here are the instructions to fund your account.</Typography>
            <Grid sx={{mt: 3, mb: 5, float: "left"}}>
              <Typography>1. Please download and make an account on Coinbase.</Typography>
              <Typography>2. Please connect your bank account and purchase <b>${numberWithCommas(user.fund_amount.toString())}</b> of USDC.</Typography>
              <Typography>3. The earnJARVIS team will be in contact with you to go over your account and to transfer your USDC.</Typography>
            </Grid>
          </>
        }
        
        <img src={backgroundImg} />
      </Box>
    )
  }
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, null)(EmptyDashboard);