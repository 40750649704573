import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography, 
  OutlinedInput
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ApiHelper from "../../helpers/apiHelper";
import { toast } from "react-toastify";

type QuestionProps = {
  sub_title: string
  data: {
    title: string,
    sub_title: string,
    answers: (string & number)[]
  }
  previousQuestion: string,
  single?: boolean
}

export default function CheckboxLists(props: QuestionProps) {
  const { sub_title, data } = props;
  const [other, setOther] = useState<string>('');
  const [answers, setAnswers] = useState<number[]>([]);
  const navigate = useNavigate();

  const handleAnswers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = answers.findIndex(item => item === parseInt(value));
    
    if (index === -1) {
      setAnswers([...answers, parseInt(value)]);
    } else {
      answers.splice(index, 1);
    }
  }

  const handlePrevious = () => {
    navigate(`/survey/${props.previousQuestion}`);
  }

  const handleContinue = () => {
    if (props.single === true && (answers.length > 1 || answers.length == 0)) {
      toast.warn("Please select one");
      return true
    }

    if (answers.length == 0 && other == "") {
      toast.warn("Please select or type.");
      return true
    }
    
    ApiHelper.post("/api/survey/save_answers/", {
      sub_title: sub_title,
      answers: answers,
      other: other
    })
    .then((res: any) => {
      navigate(`/survey/${res.data.next_question}`);
    }).catch(() => {
      toast.error("Please try again.");
    })
  }

  return (
    <>
      <ArrowBackIosNewIcon onClick={handlePrevious}/>
      <Box 
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box maxWidth="sm">
          <Typography className="page_title">{data.title}</Typography>
          <Grid container>
            {data.answers.map((answer, key) => (
              <Grid item sm={6} key={key}>
                <div className={key % 2 == 0 ? "checkbox-button" : "checkbox-button float-right"}>
                  <label>
                    <input type="checkbox" value={answer["value"]} onChange={handleAnswers} />
                    {answer["description"] === '' ? 
                      <div>
                        <Typography sx={{padding: "10px"}}>{answer["label"]}</Typography>
                      </div>
                       :
                      <div>
                        <Typography sx={{padding: "10px 0 0 10px"}}>{answer["label"]}</Typography>
                        <Typography className="example-description">{answer["description"]}</Typography>
                      </div> 
                    }     
                  </label>
                </div>
              </Grid>
            ))}
            {props.single ? null :
              <Grid item sm={12}>
                <OutlinedInput
                  fullWidth
                  sx={{mt: 2}}
                  placeholder="Other"
                  value={other}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOther(e.target.value)}
                />
              </Grid>
            }
          </Grid>
          <Button 
            fullWidth 
            variant="contained"
            sx={{mt: 3}}
            className="submit-button"
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  )
}