import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlaidLink, PlaidLinkOnSuccess } from 'react-plaid-link';
import { 
  Grid, 
  Box, 
  Typography,
  Button,
  ListItem,
  ListItemText
} from '@mui/material';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Heading from '../../components/Heading';
import ApiHelper from '../../helpers/apiHelper';
import { toast } from 'react-toastify';
import Sidebar from '../../components/Sidebar';

export default function LinkBank() {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  useEffect(() => {
    ApiHelper.get("/api/create_link_token")
    .then((res: any) => {
      setToken(res.data.link_token);
    })
  }, []);

  const handlePrevious = () => {
    navigate("/fund_account/stake_blockchain_assets");
  }

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (public_token, metadata) => {
      ApiHelper.post("/api/get_access_token", {
        public_token: public_token,
        accounts: metadata.accounts,
        bank_id: metadata.institution?.institution_id,
        bank_name: metadata.institution?.name
      }).then(() => {
        toast.success("Success!");
        navigate("/fund_account/deposit_amount");
      }).catch(() => {
        toast.error("Fail!. Please try again.")
      })
    },
    []
  );

  return (
    <Grid className="container">
      <Sidebar 
        portfolio={true} 
        fund_account={true}
      />
      <Heading text="Fund your account" />
      <Grid className="main">
        <ArrowBackIosNewIcon onClick={handlePrevious}/>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box maxWidth="sm">
            <Typography className="page_title">How would you like to fund your new earnJarvis Account?</Typography>
            <Grid container justifyContent="center">
            {token !== null ?
              <>
                <Grid item sx={{ mt: 10, mb: 2 }}> 
                  <PlaidLink
                    token={token}
                    onSuccess={onSuccess}
                    className="btn_add_bank"
                  >
                    <ListItem className="link_bank">
                      <ListItemText
                        primary={
                          <Typography className="title">Link your external bank Account</Typography>
                        }
                      />
                      <ControlPointIcon style={{marginLeft: 100, color: "#265ADF"}} />
                    </ListItem>
                  </PlaidLink>
                </Grid>
                <Grid item>
                  <ListItem className="link_bank" onClick={() => navigate("/fund_account/deposit_amount")}>
                    <ListItemText
                      primary={
                        <Typography className="title">Transfer USDC from an external crypto wallet</Typography>
                      }
                    />
                    <ControlPointIcon style={{marginLeft: 100, color: "#265ADF"}}  />
                  </ListItem>
                </Grid>
              </> : <Typography>Please wait...</Typography>
            }
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}