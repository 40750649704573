import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Link,
  Grid,
  Box,
  FormHelperText,
  OutlinedInput,
  Typography,
  InputLabel,
  Checkbox,
  MenuItem,
  FormControl
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DateOptions, MonthOptions, YearOptions } from "../../variables/index";
import { toast } from 'react-toastify';
import axios from 'axios';
import Heading from '../../components/Heading';
import { validateEmail, validatePassword } from '../../helpers/commonHelper';
import { signup } from "../../redux/actions/auth";
import User from '../../models/User';
import Sidebar from '../../components/Sidebar';

const SignUp = (props: any) => {
  const navigate = useNavigate();
  const [ipAddress, setIpAddress] = useState('');
  const [checked, setChecked] = useState(false);
  const [date, setDate] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [valid, setValid] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPwd: false
  });

  const [errorText, setErrorText] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPwd: ''
  });

  useEffect(() => {
    getIpAddress();
  }, []);

  const getIpAddress = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    setIpAddress(res.data.IPv4);
  } 

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      setErrorText({...errorText, [e.target.name]: ''});
      setValid({...valid, [e.target.name]: false});
    }
  }

  const handleSignUp = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      firstName: { value: string };
      lastName: { value: string };
      email: { value: string };
      password: { value: string };
      confirmPwd: { value: string };
    };
    
    const firstName = target.firstName.value;
    const lastName = target.lastName.value;
    const email = target.email.value;
    const password = target.password.value;
    const confirmPwd = target.confirmPwd.value;
    const dateOfBirth = `${year}-${month}-${date}`;

    if (firstName === '') {
      setErrorText({...errorText, firstName: 'First Name is required'});
      setValid({...valid, firstName: true});
      return;
    }

    if (lastName === '') {
      setErrorText({...errorText, lastName: 'Last Name is required'});
      setValid({...valid, lastName: true});
      return;
    }

    if (email === '') {
      setErrorText({...errorText, email: 'Email is required'});
      setValid({...valid, email: true});
      return;
    }

    if (!validateEmail(email)) {
      setErrorText({...errorText, email: 'Email is invalid'});
      setValid({...valid, email: true});
      return;
    }

    if (date === '' || month === '' || year === '') {
      toast.error("Please select your birth date.");
      return;
    }

    if (password === '') {
      setErrorText({...errorText, password: 'Password is required'});
      setValid({...valid, password: true});
      return;
    }

    if(!validatePassword(password)) {
      setErrorText({...errorText, password: 'Password should be at least 8 characters, and should contain capital & lower letter and number.'});
      setValid({...valid, password: true});
      return;
    }

    if (confirmPwd === '') {
      setErrorText({...errorText, confirmPwd: 'Confirm password is required'});
      setValid({...valid, confirmPwd: true});
      return;
    }

    if (password !== confirmPwd) {
      setErrorText({...errorText, confirmPwd: 'Password does not match.'});
      setValid({...valid, confirmPwd: true});
      return;
    }

    if (checked !== true) {
      toast.error("Please agree with our policy.");
      return;
    }

    props.signup({firstName, lastName, email, password, dateOfBirth, ipAddress})
    .then(() => {
      toast.success("Success!");
      navigate("/email_verification");
    })
    .catch(() => {
      toast.error("Fail! User with this email already exists.");
    })
  };

  return (
    <Grid className="container">
      <Sidebar />
      <Heading text="Create an Account" />
      <Grid className="main">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography className="page_title">Get your earnJARVIS account to get started. </Typography>
          <Box component="form" noValidate onSubmit={handleSignUp}  maxWidth="sm" sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel className="input-label">Legal First Name</InputLabel>
                <OutlinedInput 
                  fullWidth 
                  name="firstName" 
                  error={valid.firstName} 
                  onChange={handleChangeInput} 
                />
                <FormHelperText className='text-danger'>{errorText.firstName}</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className="input-label">Legal Last Name</InputLabel>
                <OutlinedInput 
                  fullWidth 
                  name="lastName" 
                  error={valid.lastName} 
                  onChange={handleChangeInput} 
                />
                <FormHelperText className='text-danger'>{errorText.lastName}</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel className="input-label">Email Address</InputLabel>
                <OutlinedInput 
                  fullWidth 
                  name="email" 
                  error={valid.email} 
                  onChange={handleChangeInput} 
                />
                <FormHelperText className='text-danger'>{errorText.email}</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel className="input-label">Date of Birth</InputLabel>
                <FormControl sx={{m: 1, minWidth: 180, margin: '0 30px 0 0'}}>
                  {/* <InputLabel id="month-label">MM</InputLabel> */}
                  <Select
                    labelId="month-label"
                    displayEmpty
                    // input={<OutlinedInput />}
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={month}
                    onChange={(e: SelectChangeEvent) => setMonth(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 500
                        }
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      MM
                    </MenuItem>
                    {MonthOptions.map((date, key) => (
                      <MenuItem key={key} value={date.value}>{date.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{m: 1, minWidth: 180, margin: 0}}>
                  {/* <InputLabel id="date-label">DD</InputLabel> */}
                  <Select
                    labelId="date-label"
                    displayEmpty
                    // input={<OutlinedInput />}
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={date}
                    onChange={(e: SelectChangeEvent) => setDate(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 500
                        }
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      DD
                    </MenuItem>
                    {DateOptions.map((date, key: number) => (
                      <MenuItem key={key} value={date.value}>{date.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{m: 1, minWidth: 180, margin: 0, float: "right"}}>
                  {/* <InputLabel id="year-label">YYYY</InputLabel> */}
                  <Select
                    labelId="year-label"
                    displayEmpty
                    // input={<OutlinedInput />}
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={year}
                    onChange={(e: SelectChangeEvent) => setYear(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 500
                        }
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      YYYY
                    </MenuItem>
                    {YearOptions.map((date, key) => (
                      <MenuItem key={key} value={date.value}>{date.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="input-label">Create a Password</InputLabel>
                <OutlinedInput 
                  fullWidth 
                  name="password" 
                  type="password" 
                  error={valid.password} 
                  onChange={handleChangeInput} 
                />
                <FormHelperText className='text-danger'>{errorText.password}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="input-label">Confirm Password</InputLabel>
                <OutlinedInput 
                  fullWidth 
                  type="password" 
                  name="confirmPwd" 
                  error={valid.confirmPwd} 
                  onChange={handleChangeInput} 
                />
                <FormHelperText className='text-danger'>{errorText.confirmPwd}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>
                    I certify that I am 18 years or older and have read and accepted the <Link href="https://earnjarvis.com/terms-of-use/" target="_blank">Terms & Conditions</Link>.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button 
                  fullWidth 
                  type="submit" 
                  variant="contained" 
                  sx={{ mt: 3, mb: 2 }} 
                  className="submit-button"
                >
                  Create
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography className="default-font">
                  Already have an account? <Link href="/login" className="default-font">Log in</Link>
                  </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = (dispatch: any) => ({
  signup: (userData: User) => dispatch(signup(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);