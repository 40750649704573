import React from "react";
import { useParams } from 'react-router-dom';
import { Grid } from "@mui/material";
import Recommendation from "../../components/FundAccount/Recommendation";
import StakeBlockchainAssets from "../../components/FundAccount/StakeBlockchainAssets";
import Sidebar from "../../components/Sidebar";
import DepositAmount from "../../components/FundAccount/DepositAmount";

export default function FundAccount() {
  const { sub_category } = useParams();

  return (
    <Grid className="container">
      <Sidebar 
        portfolio={true} 
        // portfolio_done={true} 
        fund_account={sub_category === "deposit_amount" ? true : false} 
        // fund_account_done={sub_category === "deposit_amount" ? true : false}
      />
      {sub_category === "recommendation" ? <Recommendation /> : null}
      {sub_category === "stake_blockchain_assets" ? <StakeBlockchainAssets /> : null}
      {sub_category === "deposit_amount" ? <DepositAmount /> : null}
    </Grid>
  )
}