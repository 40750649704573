import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Button
} from '@mui/material';
import { connect } from "react-redux";
import Persona from 'persona';
import { Fields } from 'persona/dist/lib/interfaces';
import { toast } from 'react-toastify';
import Heading from '../../components/Heading';
import ApiHelper from '../../helpers/apiHelper';
import Sidebar from '../../components/Sidebar';
import identityVerificationImg from "../../assets/images/identity_verification.png";
import { getUserInfo } from "../../redux/actions/auth";

type Verification = {
  inquiryId: string,
  status: string,
  fields: Fields
}

const IdentityVerification = (props: any) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleContinue = (e: React.SyntheticEvent) => {
    setClicked(true)
  }

  const onSuccess = ({inquiryId, status, fields}: Verification) => {
    // Inquiry completed. Optionally tell your server about it.
    console.log(`Sending finished inquiry ${inquiryId} to backend`);
    ApiHelper.post("/api/auth/identity_verification/", {
      inquiry_id: inquiryId,
      status: status,
      fields: fields
    }).then(() => {
      toast.success("Success!");
      navigate("/survey/funds_plan");
      props.getUserInfo();
    }).catch(() => {
      toast.error("Please try again.");
    })
}

  return (
    <Grid className="container">
      <Sidebar />
      <Heading text="Verify your Identity" />
      <Grid className="main">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={identityVerificationImg} />
          <Grid container justifyContent="center" maxWidth="sm" sx={{mt: 5}}>
            <Typography className="default-font">
              Begin Identity Verification with Persona
            </Typography>
            {clicked ?
              <Persona.Inquiry
                templateId='itmpl_WY5MwYbfsMUugkJmZor5QexQ'
                environment='production'
                referenceId={props.user.uuid}
                fields={{
                  nameFirst: props.user.first_name,
                  nameLast: props.user.last_name,
                  birthdate: props.user.date_of_birth
                }}
                onLoad={() => { console.log('Loaded inline'); }}
                onComplete={onSuccess}
              /> :
              <Grid item xs={12}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  sx={{ mt: 6, mb: 3, width: "100%" }}
                  className="submit-button"
                  onClick={handleContinue}
                >
                  Continue
                </Button>
              </Grid>
            }
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserInfo: () => dispatch(getUserInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(IdentityVerification);