import { Dispatch } from "redux";
import AuthHelper from '../../helpers/authHelper';
import User from '../../models/User';
import { AuthActionTypes } from './types';

export const login = (email: string, password: string) => (dispatch: Dispatch) => {
  dispatch({type: AuthActionTypes.LOGIN_REQUEST});
  return AuthHelper.login(email, password)
    .then((res: any) => {
      dispatch({
        type: AuthActionTypes.LOGIN_SUCCESS,
        payload: res.data,
      });
    }).catch((err: any) => {
      dispatch({
        type: AuthActionTypes.LOGIN_FAILURE,
      });
      throw(err);
    });
};

export const logout = () => {
  return {
    type: AuthActionTypes.LOGOUT
  }
};

export const getUserInfo = () => (dispatch: Dispatch) => {
  dispatch({type: AuthActionTypes.GET_USER_INFO_REQUEST});
  return AuthHelper.getUserInfo()
    .then((res: any) => {
      dispatch({
        type: AuthActionTypes.GET_USER_INFO_SUCCESS,
        payload: res.data
      });
    }).catch(() => {
      dispatch({
        type: AuthActionTypes.GET_USER_INFO_FAILURE,
      });
    });
};

export const signup = (userInfo: User) => (dispatch: Dispatch) => {
  dispatch({type: AuthActionTypes.SIGNUP_REQUEST});
  return AuthHelper.signup(userInfo)
    .then((res: any) => {
      dispatch({
        type: AuthActionTypes.SIGNUP_SUCCESS,
        payload: res.data,
      });
    }).catch((err: object) => {
      dispatch({
        type: AuthActionTypes.SIGNUP_FAILURE,
      });
      throw(err);
    });
};

export const phoneVerification = (code: string) => (dispatch: Dispatch) => {
  dispatch({type: AuthActionTypes.TWO_FACTOR_AUTHENTICATION_REQUEST});
  return AuthHelper.phoneVerification(code)
    .then((res: any) => {
      dispatch({
        type: AuthActionTypes.TWO_FACTOR_AUTHENTICATION_SUCCESS,
        payload: res.data
      });
    }).catch(() => {
      dispatch({
        type: AuthActionTypes.TWO_FACTOR_AUTHENTICATION_FAILURE,
      });
    });
}

export type AuthActions =
  | ReturnType<typeof login>
  | ReturnType<typeof logout>
  | ReturnType<typeof signup>
  | ReturnType<typeof getUserInfo>