import React, { useState, useEffect } from "react";
import {
  Box, 
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from "@mui/material";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import NavBar from '../../components/Navbar';
import ApiHelper from "../../helpers/apiHelper";
import BalanceNav from "../../components/Dashboard";
import { numberWithCommas } from "../../helpers/commonHelper";
import CryptoLogo from "../../components/CryptoLogo";
import { getUserInfo } from "../../redux/actions/auth";
import EmptyDashboard from "../../components/Dashboard/EmptyDashboard";

type BucketDataType = {
  bucket_name: string,
  data: {
    usd_value: number,
    asset_name: string,
    asset_ticker: string,
    asset_number: number
  }[]
}[]

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">Total: {payload[0].payload.total}</p>
        {payload.sort().reverse().map((data: any, key: number) => (
          <p className="desc" key={key} style={{color: data.color}}>{data.dataKey} : {data.value}</p>
        ))}
      </div>
    )
  }

  return null;
};

const Dashboard = (props: any) => {
  const [graphData, setGraphData] = useState();
  const [bucketNames, setBucketNames] = useState<string[]>([]);
  const [bucketData, setBucketData] = useState<BucketDataType>();
  const [balanceData, setBalanceData] = useState({
    account_value: 0,
    account_rewards: 0,
    net_contributions: 0
  });

  useEffect(() => {
    props.getUserInfo();
  }, []);

  useEffect(() => {
    ApiHelper.get("/api/get_user_account_financials")
    .then((res: any) => {
      setBalanceData(res.data);
    }).catch(() => {
      toast.error("Please try again.");
    });
  }, []);

  useEffect(() => {
    ApiHelper.get("/api/get_user_bucket_value")
    .then((res: any) => {
      setGraphData(res.data.results);
      setBucketNames(res.data.bucket_names);
    }).catch(() => {
      toast.error("Please try again.");
    })
  }, []);

  useEffect(() => {
    ApiHelper.get("/api/get_user_bucket_instance")
    .then((res: any) => {
      setBucketData(res.data);
    }).catch(() => {
      toast.error("Please try again.");
    })
  }, []);

  if (!balanceData) {
    return null
  } else {
    if (balanceData.net_contributions == 0) {
      return(
        <Grid className="container">
          <NavBar active="dashboard" />
          <EmptyDashboard />
        </Grid>
      )
    } else {
      return (
        <Grid className="container">
          <NavBar active="dashboard" />
          <BalanceNav data={balanceData} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // background: "#E5E5E5"
            }}
          >
            <Typography className="chart-title">Balance</Typography>
            <ResponsiveContainer width="90%" height={500}>
              <BarChart
                data={graphData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis label={{ value: 'Dollars($)', angle: -90, position: 'insideLeft', dx: -20 }} />
                <Tooltip content={<CustomTooltip />}/>
                <Legend layout="horizontal" verticalAlign="top" align="right" style={{padding: "10px"}}/>
                {bucketNames && bucketNames.sort().reverse().map((name, key) => (
                  <>
                    {(name == "Blue Chip") && <Bar key={key} dataKey={name} stackId="a" fill="#00C2FF"/>}
                    {(name == "Decentralized Finance") && <Bar key={key} dataKey={name} stackId="a" fill="#26ADDF"/>}
                    {(name == "Meme Tokens") && <Bar key={key} dataKey={name} stackId="a" fill="#2680DF"/>}
                    {(name == "Metaverse") && <Bar key={key} dataKey={name} stackId="a" fill="#265ADF"/>}
                    {(name == "SmartContract Blockchains") && <Bar key={key} dataKey={name} stackId="a" fill="#041E96"/>}
                    {(name == "Web3 Infrastructure") && <Bar key={key} dataKey={name} stackId="a" fill="#061662"/>}
                  </>
                ))}
              </BarChart>
            </ResponsiveContainer>
            <Divider style={{width: "100%"}} />
            <Grid container>
              {bucketData && bucketData.map((bucket, key) => (
                <>
                  <Grid item sm={3} key={key} className="bucket-card">
                    <Typography className="title">{bucket.bucket_name}</Typography>
                    <Grid container className="card-header">
                      <Grid item sm={8}>
                        <Typography>Assets</Typography>
                      </Grid>
                      <Grid item sm={4}>
                        <Typography sx={{textAlign: "right"}}>USD Value</Typography>
                      </Grid>
                      {/* <Grid item sm={2}>
                        <Typography>Growth</Typography>
                      </Grid> */}
                    </Grid>
                    <Divider />
                    {bucket.data.map((data, key) => (
                      <>
                        <List key={key}>
                          <ListItem>
                            <ListItemAvatar>
                              <CryptoLogo name={data.asset_ticker} width="40px" height="40px" />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography className="asset-name">{data.asset_name}</Typography>
                              }
                              secondary={
                                <Typography className="asset-info">{data.asset_number} {data.asset_ticker}</Typography>
                              }
                            />
                            <ListItemText>
                              <Typography className="asset-name" sx={{textAlign: "right"}}>${numberWithCommas(data.usd_value.toString())}</Typography>
                            </ListItemText>
                            {/* <Typography>24%</Typography> */}
                          </ListItem>
                        </List>
                        <Divider />
                      </>
                    ))}
                    {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
                  </Grid>
                  {((key + 1) % 4 == 0) ? <Divider style={{width: "100%"}} /> : null}
                </>
              ))}
            </Grid>
            <Divider style={{width: "100%"}} />
          </Box>
        </Grid>
      )
    }
  }
}

// const mapStateToProps = (state: any) => ({
//   user: state.auth.user
// });

const mapDispatchToProps = (dispatch: any) => ({
  getUserInfo: () => dispatch(getUserInfo())
});

export default connect(null, mapDispatchToProps)(Dashboard);