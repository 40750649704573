import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography, 
  ListItem,
  ListItemText,
  List,
  Checkbox,
  Button
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ChevronRight } from '@mui/icons-material';
import { toast } from "react-toastify";
import ApiHelper from "../../helpers/apiHelper";

type QuestionProps = {
  sub_title: string
  data: {
    title: string,
    sub_title: string,
    answers: (string & number)[]
  }
  previousQuestion: string,
  checkbox?: boolean
}

export default function BlockLists(props: QuestionProps) {
  const { data, sub_title, previousQuestion } = props;
  const [answers, setAnswers] = useState<number[]>([]);
  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate(`/survey/${previousQuestion}`);
  }

  const handleAnswers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = answers.findIndex(item => item === parseInt(value));
    
    if (index === -1) {
      setAnswers([...answers, parseInt(value)]);
    } else {
      answers.splice(index, 1);
    }
  }

  const handleClick = (value: string) => {
    if (value === "") {
      toast.warn("Please select.");
      return true
    }

    ApiHelper.post("/api/survey/save_answers/", {
      sub_title: sub_title,
      answers: [parseInt(value)],
      other: ""
    })
    .then((res: any) => {
      if (res.data.next_question === "fund_account") {
        navigate("/fund_account/recommendation");
      } else {
        navigate(`/survey/${res.data.next_question}`);
      }
    }).catch(() => {
      toast.error("Please try again.");
    })
  }

  const handleContinue = () => {
    if (answers.length == 0) {
      toast.warn("Please select.");
      return true
    }
    
    ApiHelper.post("/api/survey/save_answers/", {
      sub_title: sub_title,
      answers: answers,
      other: ""
    })
    .then((res: any) => {
      navigate(`/survey/${res.data.next_question}`);
    }).catch(() => {
      toast.error("Please try again.");
    })
  }

  return (
    <>
      {props.previousQuestion != '' ? <ArrowBackIosNewIcon onClick={handlePrevious} /> : null }
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box maxWidth="sm">
          <Typography className="page_title">{data.title}</Typography>
          <Grid container justifyContent="center" sx={{mt: 5}}>
            <List sx={{width: '100%', maxWidth: 500}}>
              {data.answers.map((answer, key) => (
                (props.checkbox ? 
                  <ListItem className="survey_list_item" key={key}>
                    <ListItemText
                      primary={
                        <Typography className="survey_list_title">{answer["label"]}</Typography>
                      }
                      secondary={
                        <Typography className="survey_list_info">{answer["description"]}</Typography>
                      }
                    />
                    <Checkbox 
                      style={{marginLeft: 100}}
                      value={answer["value"]}
                      onChange={handleAnswers}
                    />
                  </ListItem> :
                  <ListItem className="survey_list_item" key={key} onClick={() =>handleClick(answer["value"])}>
                    <ListItemText
                      primary={
                        <Typography className="survey_list_title">{answer["label"]}</Typography>
                      }
                      secondary={
                        <Typography className="survey_list_info">{answer["description"]}</Typography>
                      }
                    />
                    <ChevronRight style={{marginLeft: 100}}/>
                  </ListItem>
                )
              ))}
              {props.checkbox ? 
                <Button 
                  fullWidth 
                  variant="contained"
                  sx={{mt: 3}}
                  className="submit-button"
                  onClick={handleContinue}
                >
                  Continue
                </Button> : null
              }
            </List>
          </Grid>
        </Box>
      </Box>
    </>
  )
}